import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "auto-connect/:id",
    loadChildren: () => import("./pages/auto-connect-page/auto-connect-page.module").then((m) => m.AutoConnectPageModule)
  },
  {
    path: "",
    pathMatch: "full",
    loadChildren: () => import("./pages/facing-screen-page/facing-screen-page.module").then((m) => m.FacingScreenPageModule)
  },
  {
    path: "**",
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
