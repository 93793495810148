import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CoreStorageKeys } from "../models";
import { AccountService, LocalStorageService } from "../services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService, private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const accessToken = this.getAccessToken();
    const authHeader = accessToken ? `Bearer ${accessToken}` : "";

    // Clone the request to add the new header.

    // const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
    // OR shortcut
    const authReq = req.clone({
      setHeaders: {
        Authorization: authHeader
      }
    });
    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }

  private getAccessToken(): string {
    let accessToken: string = this.accountService.accessToken;

    if (!accessToken) {
      accessToken = this.localStorageService.getItem(CoreStorageKeys.accessToken);

      if (accessToken) {
        this.accountService.setAccessToken(accessToken);
      }
    }

    return accessToken;
  }
}
