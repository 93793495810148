import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { VersionDetails } from "./environments/helpers/version-details";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

console.log(`Version: ${VersionDetails.versionFull}`);
