import { ErrorHandler, Injectable, ApplicationRef, Injector } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private inj: Injector) {}

  handleError(errorResponse: any): void {
    if (errorResponse.status === 401) {
      console.error("Unauthorised", "Pleae login again.");
      this.inj.get(ApplicationRef).tick();
      this.inj.get(AuthService).login().subscribe();
    } else if (errorResponse.status === 400) {
      console.log("***** HANDLE ERROR *****");
      console.error(errorResponse.error.message);
      this.inj.get(ApplicationRef).tick();
    } else {
      console.error(errorResponse.message, JSON.stringify(errorResponse.stack));
    }

    console.error(errorResponse);
    // IMPORTANT: Don't Rethrow the error otherwise it will not emit errors after once
    // https://stackoverflow.com/questions/44356040/angular-global-error-handler-working-only-once
    // throw errorResponse;
  }
}
