import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActionBackButtonComponent, ConfirmActivityModalComponent, IframeComponent, ToasterComponent } from ".";
import { TranslocoRootModule } from "@app-cfs/core/transloco-root.module";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { SpinnerComponent } from "@app-cfs/shared/components/spinner";

@NgModule({
  imports: [CommonModule, TranslocoRootModule, NgbToastModule],
  declarations: [IframeComponent, ActionBackButtonComponent, ConfirmActivityModalComponent, ToasterComponent, SpinnerComponent],
  exports: [IframeComponent, ActionBackButtonComponent, ConfirmActivityModalComponent, ToasterComponent, SpinnerComponent]
})
export class SharedComponentsModule {}
