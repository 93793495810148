<ng-container *transloco="let t; read: 'templateActionPage.confirmActionModal'">
  <div class="modal-body d-flex">
    <div class="d-flex flex-column pt-4 align-items-center w-100">
      <span class="modal-text text-center pt-1">{{ t("text") }}</span>

      <span class="countdown-wrapper d-inline-flex">
        <span class="countdown d-inline-flex justify-content-end">{{ secondsLeft$ | async }}</span> {{ t("seconds") }}
      </span>
    </div>
  </div>

  <div class="modal-footer two-actions">
    <button class="btn btn-outline" (click)="closeModal()">
      {{ "templateActionPage.backBtn" | transloco }}
    </button>

    <button class="btn primary-btn" (click)="confirmActivity()">
      {{ t("confirmBtn") }}
    </button>
  </div>
</ng-container>
