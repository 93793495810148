export const environment = {
  production: false,
  baseName: "cfsdemo",
  apiName: "apidemo",
  pubNubConfig: {
    publishKey: "pub-c-fb63e109-cb52-463b-9d2f-7faad9038ff4",
    subscribeKey: "sub-c-7590b285-26b6-4c75-a10b-2d78e4c2b372",
    uuid: "app-8E66A4AAEBE24C1683D9022E5EBFB231"
  },
  authConfig: {
    clientId: "kiosk-client",
    scope: "clearline_api",
    redirectUrl: "/callback",
    logoutUrl: ""
  }
};
