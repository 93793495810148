import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedComponentsModule } from "@app-cfs/shared/components";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { CarouselComponent } from "./carousel.component";
import { CFSCarouselComponent } from "./cfs-carousel/cfs-carousel.component";
import { HtmlTemplateComponent } from "./html-template/html-template.component";
import { SecretGestureComponent } from "./secret-gesture/secret-gesture.component";
import { TemplatePreviewComponent } from "./template-preview";
import { TemplateComponent } from "./template/template.component";
import { MenuButtonComponent } from "./menu-button";

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [CommonModule, LottieModule.forRoot({ player: playerFactory }), SharedComponentsModule],
  declarations: [
    CarouselComponent,
    CFSCarouselComponent,
    TemplateComponent,
    HtmlTemplateComponent,
    TemplatePreviewComponent,
    SecretGestureComponent,
    MenuButtonComponent
  ],
  exports: [CarouselComponent]
})
export class CarouselModule {}
