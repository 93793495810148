import { Injectable } from "@angular/core";
import { AppConfigService } from "core-kit";

@Injectable({ providedIn: "root" })
export class WebService {
  constructor(private configService: AppConfigService) {}

  getFullUrl(url: string): string {
    const { webUrl } = this.configService.appData;

    return `${webUrl}/${url}`;
  }
}
