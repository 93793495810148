import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject, timer } from "rxjs";
import { map, take } from "rxjs/operators";

@Component({
  templateUrl: "./confirm-activity-modal.component.html",
  styleUrls: ["./confirm-activity-modal.component.scss"]
})
export class ConfirmActivityModalComponent implements OnInit, OnDestroy {
  constructor(public dialogRef: MatDialogRef<ConfirmActivityModalComponent>) {}

  private readonly countdown = 15;
  private readonly intervalPeriod = 1000;
  private secondsLeft = this.countdown;
  private resetContDown$ = new Subject<void>();

  secondsLeft$: Observable<number> | undefined;

  ngOnInit(): void {
    this.secondsLeft$ = timer(0, this.intervalPeriod).pipe(
      take(this.countdown + 1),
      map(() => {
        if (this.secondsLeft < 1) {
          this.closeModal();
        }

        return this.secondsLeft--;
      })
    );
  }

  closeModal(confirmed: boolean = false): void {
    this.dialogRef.close(confirmed);
  }

  confirmActivity(): void {
    this.closeModal(true);
  }

  ngOnDestroy(): void {
    this.resetContDown$.next();
    this.resetContDown$.complete();
  }
}
