import { Injectable, TemplateRef } from "@angular/core";

import { Toast, ToastOptions } from "./toaster";

@Injectable({ providedIn: "root" })
export class ToastService {
  toasts: Toast[] = [];
  delay = 5000;

  constructor() {}

  info(content: any) {
    this.show(content, { delay: this.delay, className: "bg-info text-light" });
  }

  success(content: any) {
    this.show(content, { delay: this.delay, className: "bg-success text-light" });
  }

  danger(content: any) {
    this.show(content, { delay: this.delay, className: "bg-danger text-light" });
  }

  show(content: TemplateRef<any> | null, options: ToastOptions = { delay: this.delay, className: "" }) {
    this.toasts.push({ content, ...options });
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
