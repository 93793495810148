import { Component } from "@angular/core";
import { SpinnerService } from "./spinner.service";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent {
  isVisible$ = this.spinnerService.isVisible$;

  constructor(private spinnerService: SpinnerService) {}
}
