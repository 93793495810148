import { Injectable } from "@angular/core";
import { ProfileModel } from "../models";
import { JwtHelperService } from "./jwtHelperService";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

@Injectable()
export class AccountService {
  public jwtHelper: JwtHelperService = new JwtHelperService();

  constructor() {}

  private _accessToken = "";
  private readonly _isAuthed$ = new BehaviorSubject<boolean>(false);

  readonly isAuthed$ = this._isAuthed$.pipe(filter(Boolean), distinctUntilChanged());

  get accessToken(): string {
    return this._accessToken;
  }

  public get cashRegisterAccount(): ProfileModel | undefined {
    if (this.accessToken) {
      return this.jwtHelper.decodeToken(this.accessToken);
    }
    return undefined;
  }

  setAccessToken(token: string): void {
    this._accessToken = token;

    this._isAuthed$.next(true);
  }
}
