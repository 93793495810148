import { ChangeDetectionStrategy, Component, Inject, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { ConfirmActivityModalComponent } from "..";

export enum UserInactivityTimeout {
  Default = 3 * 60 * 100,
  PinCodeLogin = 30 * 1000,
  GestureDestination = 60 * 1000,
  Menu = 30 * 1000
}

export class UserActivityConfirmData {
  enabled?: boolean;
  inactivityTimeout?: UserInactivityTimeout;
}

@Component({
  selector: "app-user-activity-confirm",
  templateUrl: "./user-activity-confirm.component.html",
  styleUrls: ["./user-activity-confirm.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class UserActivityConfirmComponent implements OnInit, OnDestroy {
  private dialog: MatDialog = inject(MatDialog);
  private leaveTimeout: number | null = null;
  private inactivityTimeout: number;

  constructor(@Inject(UserActivityConfirmData) private data?: UserActivityConfirmData) {
    this.inactivityTimeout = data?.inactivityTimeout ?? UserInactivityTimeout.Default;
  }

  abstract onUserInactivity(): void;

  ngOnInit(): void {
    if (this.data?.enabled) {
      this.prolongUserActivity();
    }
  }

  prolongUserActivity() {
    if (this.data?.enabled) {
      this.clearTimeout();
      this.scheduleLeaveConfirm();
    }
  }

  stopUserActivity(): void {
    if (this.data?.enabled) {
      this.clearTimeout();
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  private clearTimeout() {
    if (this.leaveTimeout) {
      clearTimeout(this.leaveTimeout);
      this.leaveTimeout = null;
    }
  }

  private scheduleLeaveConfirm() {
    this.leaveTimeout = setTimeout(() => {
      this.showConfirmActivityModal();
    }, this.inactivityTimeout);
  }

  private showConfirmActivityModal() {
    this.dialog
      .open(ConfirmActivityModalComponent, {
        panelClass: "modal-large",
        autoFocus: false
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((activityConfirmed: boolean | undefined) => {
        if (activityConfirmed || activityConfirmed === undefined) {
          this.prolongUserActivity();
        } else {
          this.onUserInactivity();
        }
      });
  }
}
